import React from 'react';
import {
  IconButton,
  styled,
  Box,
  Typography,
  useTheme,
  Modal,
  TextField,
} from '@mui/material';
import { ModalBox } from '../ModalBox';
import ModalHeaderBasic from '../ModalHeaderBasic';
import SendIcon from 'assets/icons/sendIcon.svg';
import { PrimaryButton } from 'common/form/ButtonStyle';
import ViewList from './ViewList';
import { CustomScrollbar } from 'common/CustomScrollbar'

const ModalBodyContent = styled(Box)({
  height: 'calc(100% - 82px)',
  padding: '16px 30px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const PromptsWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
}));

const InputWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.light,
  position: 'relative',
  width: '98.7%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.light,
  width: '96%',
  padding: 5,
  '.MuiInput-input': {
    color: '#E1E1E1',
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    '::-webkit-scrollbar': {
      width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#7a7a7a',
      borderRadius: '4px',
    },
  },
}));

const CustomContractModal = ({
  prompt,
  isModalOpen,
  functionList,
  handleKeyup,
  handleChangeCheckbox,
  handleEditDescription,
  handleCloseModal,
  handleChangePrompt,
  handleChangeDescription,
  handleGenerateContract,
  handleGenerateDescription,
}) => {
  const theme = useTheme();

  return (
    <Modal
      disableEnforceFocus={true}
      open={isModalOpen}
      handleClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{zIndex: 1400}}
      >

      <ModalBox
        sx={{
          borderRadius: '15px',
          width: '100%',
          maxWidth: '900px',
          maxHeight: 700,
          height: functionList?.length ? '90%' : 'unset',
        }}>
        <ModalHeaderBasic
          title={'Create Custom Contract'}
          subTitle={''}
          onClose={handleCloseModal}
        />
        <ModalBodyContent sx={functionList?.length ? {} : { height: '57%', gap: 0 }}>
          <PromptsWrapper>
            <CustomScrollbar style={{ overflowX: 'hidden' }}>
              {functionList?.length ? (
                <ViewList
                  functionList={functionList}
                  handleChangeCheckbox={handleChangeCheckbox}
                  handleEditDescription={handleEditDescription}
                  handleChangeDescription={handleChangeDescription}
                />
              ) : null}
            </CustomScrollbar>
          </PromptsWrapper>
          {functionList?.length ? (
            <PrimaryButton variant="contained" onClick={handleGenerateContract} sx={{marginBottom: '30px'}}>
              Generate
            </PrimaryButton>
          ) : (
            <Box id="input-validation-container">
              <Typography
                component="h3"
                sx={{ fontSize: 16, fontWeight: '600', fontFamily: theme.typography.fontFamily, margin: '5px 0' }}>
                Describe what code do you want to create?
              </Typography>

              <Box as={InputWrapper}>
                <StyledTextField
                  id="filled-multiline-flexible"
                  placeholder="Enter prompt"
                  name="query"
                  value={prompt}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxRows={3}
                  variant="standard"
                  onChange={handleChangePrompt}
                  onKeyUp={handleKeyup}
                />
                <IconButton
                  aria-label="send"
                  sx={{ position: 'absolute', right: 0, top: 0, bottom: 0 }}
                  disableRipple
                  onClick={handleGenerateDescription}>
                  <img src={SendIcon} alt="sendIcon" height="30px" width="30px" />
                </IconButton>
              </Box>

              <Typography
                sx={{ fontSize: 14, fontFamily: theme.typography.fontFamily, color: '#ba923e', margin: '5px 0' }}>
                {"*I'd like to design the smart contract to grant loyalty rewards to token holders"}
              </Typography>
            </Box>
          )}
        </ModalBodyContent>
      </ModalBox>
    </Modal>
  );
};

export default CustomContractModal;
