import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PrimaryButton } from 'common/form/ButtonStyle';
import { Input } from 'components/Input';
import AccelChainIcon from 'assets/icons/acclogo.svg';
import SolanaLogo from 'assets/icons/solana.svg';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";

const LinkStyle = styled('span')(({ theme }) => ({
  textDecoration: 'none',
  color: '#6c71e4',
  cursor: 'pointer',
  '&:hover': {},
}));

const FieldsWrapper = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  paddingRight: '5px',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '7px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#7a7a7a',
    borderRadius: '4px',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  // backgroundColor: 'green'
}));

const Form = ({ fields, handleChange, handleSubmit, callingFrom = 'signin' }) => {
  const theme = useTheme();
  return (
    <Grid container component="main" sx={{ maxWidth: '900px', minHeight: '400px', background: theme.palette.background.light }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          borderRadius: '1rem 0rem 0rem 1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#0a0b27',
        }}>
        <ContentWrapper>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img src={AccelChainIcon} alt="logo" />
            <img src={SolanaLogo} alt="logo" height={'160px'} style={{marginTop: '-50px'}}/>
          </Box>
        </ContentWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        elevation={6}
        square={"square"}
        sx={{ borderRadius: '0rem 1rem 1rem 0rem' }}>
        <Box
          sx={{
            my: callingFrom === 'signup' ? 4 : 0,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: callingFrom === 'signup' ? 'calc(100% - 64px)' : '100%',
          }}>
          <Typography component="h1" variant="h5" sx={{ color: theme.palette.text.primary, fontWeight: 600 }}>
            {callingFrom === 'signup' ? 'Sign Up' : callingFrom === 'reqreset' || callingFrom === 'reset' ? 'Reset password' : 'Sign In'}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <FieldsWrapper>
              {fields.map(({ label, name, type, value, errors, id }, ind) => (
                <Box sx={{ width: '100%' }} key={ind}>
                  <Input
                    isRequired={true}
                    name={name}
                    type={type}
                    label={label}
                    value={value}
                    typeLabel="form"
                    sx={{
                      width: '100%',
                      background: 'white',
                      color: 'black',
                      font: theme.typography.ubuntuMedium,
                      fontSize: 14,
                    }}
                    // placeholder={param.type}
                    errorStyle={{ marginTop: 0 }}
                    onChange={handleChange}
                    errorText={errors}
                  />
                </Box>
              ))}
            </FieldsWrapper>
            <PrimaryButton
              sx={{
                width: '96%',
                height: '50px',
                borderRadius: '7px',
                mt: '10px',
              }}
              fullWidth
              variant="contained"
              type="submit">
              {callingFrom === 'signup' ? 'Sign Up' : callingFrom === 'reqreset' || callingFrom === 'reset' ? 'Submit' : 'Sign In'}
            </PrimaryButton>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item sx={{ mt: 1, mr: '10px', textAlign: 'center', width: '100%' }}>
                {callingFrom !== 'reqreset' && callingFrom !== 'reset' ? (
                  <Typography sx={{ color: theme.palette.text.primary, font: theme.typography.ubuntuMedium, width: '100%' }}>
                    {callingFrom === 'signup' ? (
                      <>
                        Already have an account?
                        <Link to="/signin">
                          <LinkStyle> Sign In</LinkStyle>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/request-password-reset">
                          <LinkStyle sx={{ fontSize: '12px', fontWeight: 400, color: '#f4ba2f' }}>Forgot password?</LinkStyle>
                        </Link>
                        <br /> <br />
                        Don&apos;t have an account?
                        <Link to="/signup">
                          <LinkStyle> Sign Up</LinkStyle>
                        </Link>
                      </>
                    )}
                  </Typography>
                ) : (
                  <>
                    <Typography sx={{ color: theme.palette.text.primary, font: theme.typography.ubuntuMedium }}>
                      Go back to the login page.
                      <Link to="/">
                        <LinkStyle> Sign In</LinkStyle>
                      </Link>
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default Form;
