import queryString from 'query-string';
import { logout } from 'store/services/auth';

export const getUserState = () => {
  const userState = window.localStorage.getItem("auth");
  if(userState){
    return JSON.parse(userState);
  }
  else return false;
}

export const getRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = {},
  auth = true,
  userModoel,
  externalUrl,
} = {}) => {
  const userState = getUserState();

  const headers = {
    Authorization: `Bearer ${userState?.token}`,
    ContentType: 'application/json'
  };
  if (!auth) {
    delete headers.Authorization;
  }
  const query = queryString.stringify({
    ...params,
  });
  const response = await fetch(externalUrl ? externalUrl : `${domain + url}${query?.length ? '?' : ''}${query}`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    headers
  });
  const res = await response.json();
  if (response.status === 200) {
    return res;
  } 
  else if (res.code >= 3000 && userState?.auth) {
    // token expired
    console.log('res code clear all');
    logout();
    window?.location?.reload();
  }
  throw res;
};

export const postRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = {},
  body = {},
  auth = true,
  externalUrl,
  formData = false
} = {}) => {

  const userState = getUserState();

  const headers = formData ? {
    Authorization: `Bearer ${userState?.token}`,
  } : {
    Authorization: `Bearer ${userState?.token}`,
    'Content-Type': 'application/json',
  };
  if (!auth) {
    delete headers.Authorization;
  }
  const query = queryString.stringify({
    ...params,
  });
  const response = await fetch(externalUrl ? externalUrl : `${domain}${url}${url.includes('?') ? '&' : '?'}${query}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: formData ? body : JSON.stringify(body),
  });

  const res = await response.json();
  if (response.status === 200) {
    return { ...res, status: response.status };
  }
  else if (res.code >= 3000 && userState?._id) {
    // token expired
    console.log('res code clear all');
    logout();
    window?.location?.reload();
  }
  throw (res?.message || 'Bad Request');
};

export const putRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = {},
  body = {},
  auth = true,
  userState,
  userModoel,
} = {}) => {
  const headers = {
    Authorization: `Bearer ${userState?.playerAuth?.token}`,
    'Content-Type': 'application/json',
  };
  if (!auth) {
    delete headers.Authorization;
  }
  const query = queryString.stringify({
    ...params,
  });
  const response = await fetch(`${domain}${url}${url.includes('?') ? '&' : '?'}${query}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: JSON.stringify(body),
  });
  const res = await response.json();

  if (res.code === 200) {
    return res;
  } else if (res.code >= 3000) {
    // token expired
    userModoel?.clearAll();
  }
  return res;
};

export const deleteRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = {},
  auth = true,
  userState,
  userModoel,
} = {}) => {
  const headers = {
    Authorization: `Bearer ${userState?.playerAuth?.token}`,
  };
  if (!auth) {
    delete headers.Authorization;
  }
  const query = queryString.stringify({
    ...params,
  });
  const response = await fetch(`${domain}${url}${url.includes('?') ? '&' : '?'}${query}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers,
  });
  const res = await response.json();

  if (res.code === 200) {
    return res;
  } else if (res.code >= 3000) {
    // token expired
    userModoel?.clearAll();
  }
  return res;
};
