import { Box, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getContractsList } from 'store/services/contracts';
import ButtonConnectWallet from 'components/wallet/wallet';
import SearchBar from 'common/form/SearchBar';
import { PrimaryButton } from 'common/form/ButtonStyle';
import Loader from 'common/Loader';
import ListSmartContract from 'components/dashboard/ListSmartContract';
import CustomContractModal from 'common/Modals/CustomContract';
import useGenerateContract from 'hooks/useGenerateContract';
import { searchContract } from 'store/services/contracts';
import { useSearchParams } from 'react-router-dom';
import { setSelectedAccount } from 'store/reducers/wallet'
import { useConnectWallet } from '@subwallet-connect/react'


const MemoizedSubComponent = React.memo(ListSmartContract);

const Dashboard = () => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  const [searchKeyword, setSearchKeyword] = useState('');
  const [userContracts, setUserContracts] = useState([]);
  const [fallbackSerchResponse, setFallbackSearchResponses] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { selectedAccount, } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const {
    prompt,
    isModalOpen,
    functionList,
    loading,
    handleCloseModal,
    handleKeyup,
    handleEditDescription,
    handleChangePrompt,
    handleChangeCheckbox,
    handleChangeDescription,
    handleGenerateDescription,
    handleGenerateContract,
    setOpenModel
  } = useGenerateContract();
  
  const getContracts = async () => {
    try {
      setLoadingState(true)
      const filterParam = searchParams.get('filter')
      let params = { status: (filterParam ? filterParam : 'all') }

      const { contracts, message } = await getContractsList(params);
      if (contracts?.length) {
        console.log(contracts);
        setUserContracts(contracts);
        setFallbackSearchResponses(contracts);
      } else if (!contracts) {
        setUserContracts([]);
        let toastMessage = message || ''
        if (filterParam === 'all') {
          toastMessage = 'You are ready to create new contract'
        }
        toast.success(toastMessage, {
          style: { top: '3.5em' },
        });
      } else throw { message }
    }
    catch (err) {
      console.log(err)
      toast.error(err?.message, {
        style: { top: '3.5em' },
      });
    }
    finally {
      setLoadingState(false)
    }
  }

  useEffect(() => {
    getContracts();
  }, [searchParams.get('filter')]);

  useEffect(() => {
    if(!wallet) return;
    const _selectedAccount = selectedAccount ? selectedAccount : wallet?.accounts[0]?.address
    dispatch(setSelectedAccount(_selectedAccount));
  }, [wallet]);

  const setCreate = () => {
    setOpenModel(true)
  };

  const handleDisconnectWallet = () => {
    disconnect(wallet);
    dispatch(setSelectedAccount(''));
    
  }

  const handleSearchContract = useCallback(
    _.debounce(async (value) => {
      try {
        value = value?.trim();
        if (!value) {
          setUserContracts(fallbackSerchResponse);
          return false;
        }

        setLoadingState(true);
        setSearchKeyword(value);
        let { contracts, message } = await searchContract({ name: value })
        if (!contracts?.length) {
          throw { message }
        }
        else setUserContracts(contracts)
      }
      catch (err) {
        console.log(err)
        toast.error(err?.message, {
          style: { top: '3.5em' },
        });
      }
      finally { setLoadingState(false) }
    }, 500), [searchKeyword]);

  const LoaderTextComponent = (
    <>
      <span>Something amazing is getting generated!</span>
      <span>It may take upto few minutes...</span>
    </>
  )

  return (
    <Box
      sx={{
        padding: '20px 24px 0 24px',
        width: '100%',
        height: '100%',
      }}>
      {loading && <Loader showText={loading} children={LoaderTextComponent} />}
      <Box
        sx={{
          width: 'inherit',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: theme.palette.primary.white, font: 'normal normal bold 28px/32px Ubuntu-Bold' }}>
            Smart Contracts Dashboard
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <ButtonConnectWallet 
              connectWallet={() => connect()} 
              disconnectWallet={handleDisconnectWallet}
              wallet={wallet} 
              />
            {1 > 0 && (
              <PrimaryButton
                id="create-contract-button"
                variant="contained"
                sx={{
                  width: '134px',
                  height: '54px',
                  borderRadius: '7px',
                  marginLeft: '1rem',
                }}
                onClick={() => setCreate()}>
                Create New
              </PrimaryButton>
            )}
          </Box>

        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Typography
            sx={{
              color: theme.palette.primary.white,
              opacity: 0.8,
              font: 'normal normal 300 14px/21px Ubuntu-Medium',
              marginTop: '-50px',
            }}>
            Create and manage all your contracts here
          </Typography>
          <SearchBar onChange={(e) => handleSearchContract(e.target.value)} />
        </Box>
      </Box>
      <CustomContractModal
        prompt={prompt}
        isModalOpen={isModalOpen}
        functionList={functionList}
        handleKeyup={handleKeyup}
        handleChangeCheckbox={handleChangeCheckbox}
        handleEditDescription={handleEditDescription}
        handleCloseModal={handleCloseModal}
        handleChangePrompt={handleChangePrompt}
        handleChangeDescription={handleChangeDescription}
        handleGenerateDescription={handleGenerateDescription}
        handleGenerateContract={handleGenerateContract}
      />

      <MemoizedSubComponent
        userContracts={userContracts}
        loadingState={loadingState}
        setCreate={setCreate}
      />
    </Box>
  );
};

export default Dashboard;
