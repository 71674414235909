import astar from "../assets/images/network/astr-logo.png"
export const supportedChains = [
  {
    name: 'Astar',
    parent_chain: 'Polkadot',
    network: 'Mainnet',
    networkType: 'Substrate',
    parachain_id: '2006',
    chain_id: '592',
    symbol: 'ASTR',
    rpc_url: 'wss://rpc.astar.network',
    blockExplorerUrl: 'https://astar.subscan.io/',
    image: astar,
  },
  {
    name: 'Shibuya',
    parent_chain: 'Polkadot',
    network: 'Testnet',
    networkType: 'Substrate',
    parachain_id: '1000',
    chain_id: '81',
    symbol: 'SBY',
    rpc_url: 'wss://rpc.shibuya.astar.network',
    blockExplorerUrl: 'https://shibuya.subscan.io/',
    image: astar,
  },
];

export default supportedChains;
