import { styled } from '@mui/material/styles';
import Scrollbars from 'react-custom-scrollbars';

export const CustomScrollbar = styled(Scrollbars)(({ theme }) => ({
  '& > div:nth-child(2)': {
    '& > div': {
      backgroundColor: '#7a7a7a !important',
    },
  },
  '& > div:nth-child(3)': {
    '& > div': {
      backgroundColor: '#7a7a7a !important',
    },
  },
}));
