import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import TwitterIcon from '@mui/icons-material/Twitter';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccelChainIcon from 'assets/icons/AccelChain.svg';
import DiscordIcon from 'assets/icons/Discord.svg';
import HomeIcon from 'assets/icons/home.svg';
import DraftIcon from 'assets/icons/draft.svg';
import DeployedContractIcon from '@mui/icons-material/RocketLaunch';
import HelpCenterIcon from 'assets/icons/helpCenter.svg';
import ExpandIcon from 'assets/icons/expand.svg';
import { Tooltip } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/services/auth';

const drawerWidth = 218;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'relative',
  background: theme.palette.background.light_op,
  border: 'none',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position: 'relative',
  background: theme.palette.background.light_op,
  border: 'none',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: '86px',
  },
});

const MuiListItem = styled(ListItem)(({ theme, index }) => ({
  padding: '6px 0',
  '.MuiListItemButton-root': {
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 15
  },
}));
const MuiListItemButton = styled(ListItemButton)(({ theme, index, active }) => ({
  minHeight: 48,
//   justifyContent: open ? 'initial' : 'center',
  px: 0,
  background: index === active && theme.palette.background.light_op,
  borderRadius: index === active && '6px',
  color: index === active && '#4D71D3 !important',
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRadius: 11,
  zIndex: 1320,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyledListContainer = styled('div')(() =>({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
}));
const socialIcons = [
  {
    text: 'Twitter',
    icon: <TwitterIcon />,
    redirect: 'https://twitter.com/accelchain_',
  },
  {
    text: 'Join Discord',
    icon: <img src={DiscordIcon} alt="discord"/>,
    redirect: 'https://discord.gg/sfA4BAZA9z',
  },
];



const MiniDrawer = ({ page }) => {
  const [open, setOpen] = useState(false);
  const router = useNavigate();
  const [active, setActive] = useState(0);
//   const { tourGuide, contract, player, securityAudit } = useDispatch();

//   const { playerInfo } = useSelector((state) => state?.player);
//   const { isTourStart } = useSelector((state) => state?.tourGuide);
//   const { clearGoogleAuthCredentials } = useGCPNodes();
const playerInfo = {}
  const removeMarginTop = useMediaQuery(`@media screen and (max-height: 800px`);
//   const isAmbassador = playerInfo?.role?.includes('ambassador');
const isAmbassador = false;
//   const routes = {
//     '/smartcontract?filter=all': 0,
//     '/smartcontract?filter=drafts': 1,
//     '/smartcontract?filter=deployed': 2,
//     '/help': 4,
//   };
  const icons = [
    {
      text: 'Home',
      icon: <img src={HomeIcon} alt='' />,
      redirect: 'all',
    },
    {
      text: 'Drafts',
      icon: <img src={DraftIcon} alt='' />,
      redirect: 'draft',
    },
    {
      text: 'Deployed Contracts',
      icon: <DeployedContractIcon />,
      redirect: 'deployed',
    },
    {
      text: 'Help Center',
      icon: <img src={HelpCenterIcon} alt='' />,
      redirect: 'smartcontracts',
    }
  ];
  if(isAmbassador){
    icons.push(
      {
        text: 'Ambassador',
        icon: <WorkspacePremiumIcon/>,
        redirect: `ambassador/${playerInfo?.referralId}`,
      }
    )
  }
//   useEffect(() => {
//     if (routes[router?.asPath]) {
//       setActive(routes[router?.asPath]);
//     } else {
//       setActive(0);
//     }
//   }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const logOut = () => {
    logout();
    router('/signin', {replace: true});
  };

  const handleIconClick = (index, icon) => {
    if (page !== 'login' && page !== 'create') {
      if (index > 2) {
        router(`/${icon.redirect}`);
      } else {
        router(`/smartcontracts?filter=${icon.redirect}`);
      }
      setActive(index);
    //   contract.update({
    //     activeTab: '1',
    //   });
    }
  };

  const handleSocialIconClick = (index, icon) => {
    window.open(icon.redirect, '_blank');
  };

 

  return (
    <Drawer variant="permanent" open={open}>
      <List sx={{ mt: 2, ml: 3 }}>
        <ListItemIcon onClick={() => router('/smartcontracts')} sx={{ cursor: 'pointer' }}>
          <img src={AccelChainIcon} alt="accelchain" />
          {open && <ListItemText primary={'Accelchain'} sx={{ ml: 1 }} />}
        </ListItemIcon>
      </List>
      <IconButton
        sx={{ ml: open ? 24 : 8, mt: 8, position: 'fixed', zIndex:1, transform: open && 'rotate(180deg)' }}
        onClick={handleDrawerOpen}>
        <img src={ExpandIcon} alt='expand' />
      </IconButton>
      <StyledListContainer>
      <List sx={{ padding: '0 16px', marginTop: removeMarginTop ? 0 : '38px', display: 'flex', flexDirection: 'column' }}>
        {icons.map((icon, index) => (
          <MuiListItem key={index} disablePadding index={index} onClick={() => handleIconClick(index, icon)} >
            <Tooltip title={icon.text} placement="right" arrow>
              <MuiListItemButton disabled={page === 'login' || page === 'create'} active={active} index={index} >
                <ListItemIcon
                  sx={{
                    minWidth: '30px',
                    justifyContent: 'center',
                  }}>
                    {icon?.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={icon.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      '.MuiListItemText-primary': { font: 'normal normal normal 14px/17px Ubuntu' },
                    }}
                  />
                )}
              </MuiListItemButton>
            </Tooltip>
          </MuiListItem>
        ))}
      </List>

      <List sx={{ padding: '0 12px', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {socialIcons.map((icon, index) => (
          <Tooltip title={icon.text} placement="right" arrow key={index}>
            <MuiListItem
              key={index}
              disablePadding
              index={index}
              onClick={() => handleSocialIconClick(index, icon)}>
              <ListItemButton active={active} index={index}>
                {icon?.icon && (
                  <ListItemIcon
                    sx={{
                      minWidth: '30px',
                      justifyContent: 'center',
                    }}>
                    {icon.icon}
                  </ListItemIcon>
                )}
                {open && (
                  <ListItemText
                    primary={icon.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      '.MuiListItemText-primary': { font: 'normal normal normal 14px/17px Ubuntu' },
                    }}
                  />
                )}
              </ListItemButton>
            </MuiListItem>
          </Tooltip>
        ))}
        <Tooltip title={'Logout'} placement="right">
          <MuiListItem disablePadding sx={{ marginBottom: '1rem' }} onClick={logOut}>
            <ListItemButton active={active}>
              <ListItemIcon
                sx={{
                  minWidth: '30px',
                  justifyContent: 'center',
                  paddingLeft: open ? 0 : '18px'
                }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Logout'}
                sx={{
                  opacity: open ? 1 : 0,
                  '.MuiListItemText-primary': { font: 'normal normal normal 14px/17px Ubuntu' },
                }} 
               /> 
            </ListItemButton>
          </MuiListItem>
        </Tooltip>
      </List>
      </StyledListContainer>
    </Drawer>
  );
};
export default MiniDrawer;
