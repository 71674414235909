import React from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const StyledAccordion = styled(Accordion)(() => ({
    border: '1px solid rgb(54 54 54 / 50%)',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    backgroundColor: 'none'
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
    height: '150px',
    overflowY: 'auto',
    wordBreak: 'break-all',
    margin: '0px',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'rgb(97, 198, 255)',
    fontSize: '12px',
    fontFamily: 'monospace',
    backgroundColor: '#303030'
}));
const StyledAccordionSummary = styled(AccordionSummary)(({ expanded }) => ({
    minHeight: '40px !important',
    '.Mui-expanded': {
        margin: 0,
    },
    '.MuiAccordionSummary-content': {
        margin: 0,
    },
    '.MuiAccordionSummary-expandIconWrapper': {
        transform: `rotate(${expanded ? '0deg' : '180deg'}) !important`,
    },
}));

const OutputSection = ({ expanded, onChange, details, sx = {} }) => {
    return (
        <StyledAccordion sx={sx} expanded={expanded} onChange={onChange}>
            <StyledAccordionSummary
                expanded={expanded}
                expandIcon={<ExpandMoreIcon />}
                defaultExpanded={true}
                aria-controls="panel1-content"
                id="panel1-header">
                <Typography> Output</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                {details}
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

export default OutputSection;
