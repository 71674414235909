import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedAccount: ''
}

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setSelectedAccount: (state, { payload }) => {
            state.selectedAccount = payload
        },
    },
})

export const { setSelectedAccount } = walletSlice.actions

export default walletSlice.reducer