import { postRequest } from 'utils/httpRequest';

export const login = async (payload) => {
    const response = await postRequest({ url: 'users/login', auth: false, body: payload });
    if (response.status === 200 && response?._id) {
        const auth = JSON.stringify({ _id: response?._id, token: response?.token });
        window.localStorage.setItem("auth", auth)
    }
    return response;
}

export const signup = async (payload) => {
    const response = await postRequest({ url: 'users/signup', auth: false, body: payload });
    if (response.status === 200 && response?._id) {
        const auth = JSON.stringify({ _id: response?._id, token: response?.token });
        window.localStorage.setItem("auth", auth)
    }
    return response;
}

export const logout = async () => {
    window.localStorage.clear("auth")
}