import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateContract, generateDescription } from 'store/services/contracts';

const useGenerateContract = () => {
  const defaultPrompt = "I'd like to design a smart contract ";
  const [prompt, setPrompt] = useState(defaultPrompt);
  const [functionList, setFunctionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setOpenModel] = useState(false);
  const navigate = useNavigate();

  // const { contract } = {};
  // const contractState = {};

  const handleChangePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const handleKeyup = (e) => {
    const regex = new RegExp('^' + defaultPrompt, 'i');
    const query = e.target.value;
    if (!regex.test(query)) {
      e?.preventDefault();
      setPrompt(defaultPrompt);
    }
  };
  const handleChangeCheckbox = (event, index) => {
    const copyFunctionList = JSON.parse(JSON.stringify(functionList));
    copyFunctionList[index] = { ...copyFunctionList[index], isChecked: event.target.checked };
    setFunctionList(copyFunctionList);
  };

  const handleEditDescription = (index) => {
    const copyFunctionList = JSON.parse(JSON.stringify(functionList));
    copyFunctionList[index] = { ...copyFunctionList[index], editMode: true };
    setFunctionList(copyFunctionList);
  };
  const handleChangeDescription = (event, index) => {
    const copyFunctionList = JSON.parse(JSON.stringify(functionList));
    copyFunctionList[index] = { ...copyFunctionList[index], description: event.target.value };
    setFunctionList(copyFunctionList);
  };
  const handleOpenModal = () => {
    setOpenModel(true);
  };

  const handleCloseModal = () => {
    setOpenModel(false);
    setFunctionList([]);
    setPrompt(defaultPrompt);
  };

  const handleGenerateDescription = async () => {
    try {
      const _prompt = prompt?.trim();
      if (!_prompt?.replace(defaultPrompt?.trim(), '')?.trim()) {
        toast.error('Please write details in the box what you want to generate', {
          style: { top: '3.5em' },
        });
        return null;
      }
      setLoading(true);
      const response = await generateDescription({ prompt: _prompt?.replace(defaultPrompt, '') });
      const { status, data, message } = response;
      if (status === 200 && data && data?.length) {
        setFunctionList(data);
        return null;
      }
      throw new Error(message);
    } catch (err) {
      console.log('Error occurred while generating description', err);
      toast.error('Error occurred while generating description', {
        style: { top: '3.5em' },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateContract = async () => {
    try {
      const functionsToGenerate = functionList
        ?.filter((func) => func.isChecked)
        ?.map((func) => ({ name: func.name, description: func.description }));
      if (!functionsToGenerate?.length) {
        toast.error('Select atleast one function', {
          style: { top: '3.5em' },
        });
        return null;
      }
      setLoading(true);
      const { status, data, message } = await generateContract({ functionsToGenerate });
      if (status === 200) {
        console.log(data);
        // const copyExternalContracts = JSON.parse(JSON.stringify(externalContracts))
        // copyExternalContracts.push(data)
        // contract.setExternalContracts(copyExternalContracts);
        // contract.setSelectedExternalFile(data);
        // closePopover();
        navigate(`/smartcontracts/${data?.id}`)
        handleCloseModal();
        toast.success('Contract is generated successfully', {
          style: { top: '3.5em' },
          autoClose: 10000,
        });
        return;
      }
      throw new Error(message);

    } catch (error) {
      console.log('Error occurred while generating contract: ',error?.message || error);
      toast.error('Error occurred while generating contract', {
        style: { top: '3.5em' },
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    defaultPrompt,
    prompt,
    isModalOpen,
    loading,
    functionList,
    setLoading,
    setOpenModel,
    setFunctionList,
    handleKeyup,
    handleChangePrompt,
    handleChangeCheckbox,
    handleEditDescription,
    handleChangeDescription,
    handleOpenModal,
    handleCloseModal,
    handleGenerateDescription,
    handleGenerateContract
  }
}
export default useGenerateContract;
