import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const PrimaryButton = styled(Button)(({ theme }) => ({
  minWidth: 106,
  height: 40,
  textTransform: 'capitalize',
  color: theme.palette.primary.white,
  backgroundColor: '#4D71D3',
  padding: '10px 20px',
  font: theme.typography.ubuntuMedium,
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  width: 105,
  height: 40,
  textTransform: 'capitalize',
  color: theme.palette.primary.white,
  background: 'none',
  border: `1px solid ${theme.palette.text.primary}`,
  padding: '10px 20px',
  font: theme.typography.ubuntuMedium,
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
  opacity: 0.9,
}));
