const { styled } = require('@mui/material');

export const ModalBox = styled('div')(({ theme, maxheight, width = 600, height = 'auto' }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.background.light,
  boxShadow: 24,
  outline: 'none',
  maxHeight: maxheight,
  minWidth: width,
  height: height,
}));
