import { Card, Grid, Typography, useTheme, Tooltip } from '@mui/material';

import { Box, styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
// import ConfirmDeleteDialog from '../atom/Dialog/ConfirmDeleteDialog';

const MuiCard = styled(Card)(({ theme }) => ({
  px: 1,
  height: '180px',
  borderRadius: '8px',
  background: theme.palette.background.white,
}));

const ProjectCardItem = ({ contract }) => {

  const [, setConfirmDelOpen] = useState(false);

  const router = useNavigate();
  const theme = useTheme();

  const onCardClick = () => {
    router(`/smartcontracts/${contract._id}`);
  };

  return (
    <MuiCard sx={{ padding: '0.75rem 1.5rem' }}>
      <Box
        onClick={onCardClick}
        sx={{
          cursor: 'pointer',

          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: '100%',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              font: 'normal normal 300 12px/13px Ubuntu-Light',
              color: theme.palette.primary.dark,
              textTransform: 'capitalize',
              opacity: 0.9,
            }}>
            {`Updated On: ${moment(contract.updatedAt).format('LLL')}`}
          </Typography>{' '}
          <Grid item xs={1} sx={{ textAlign: 'right' }}>

            {/* Need to implement delete feature */}
            {contract.status !== 'deployed' && false? (
              <DeleteIcon
                onClick={(event) => {
                  event.stopPropagation();
                  setConfirmDelOpen(true);
                }}
                sx={{ cursor: 'pointer', fontSize: 17 }}
              />
            ) : contract.isContractVerified ? (
              <Tooltip title="verified contract" arrow placement='top'>
              <Box sx={{}}>
                <VerifiedIcon sx={{ fontSize: 17, color: '#70c492' }} />
              </Box>
              </Tooltip>
            ) : null}
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              opacity: 1,
              textTransform: 'capitalize',
              font: 'normal normal 16px/18px Ubuntu-Medium',
            }}>
            {contract.name}
          </Typography>

          <Typography
            sx={{
              font: 'normal normal normal 10px/11px Ubuntu-Regular',
              opacity: 0.8,
              margin: '0 8px',
              color: theme.palette.primary.white,
              padding: '4px 8px',
              backgroundColor: theme.palette.primary.pink,
              borderRadius: '4px',
            }}>
            {contract.status === 'draft' ? `draft` : `${contract.status}`}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              sx={{
                font: 'normal normal 300 14px/26px Ubuntu-Light',
                opacity: 0.8,
                color: theme.palette.primary.dark,
                // paddingRight: '32px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                lineClamp: 2,
                WebkitBoxOrient: 'vertical',
                minHeight: '45px',
              }}
              color="text.secondary">
              {contract.description || 'Description is not available'}
            </Typography>
          </Grid>
          {/* <Grid item xs={1} sx={{ textAlign: 'right' }}>
            {contract.status !== 'deployed' ? (
              <DeleteIconWithX
                onClick={() => {
                  setConfirmDelOpen(true);
                }}
              />
            ) : null}
          </Grid> */}
        </Grid>
      </Box>

      {/* <ConfirmDeleteDialog
        open={confirmDelOpen}
        title={'Are you sure?'}
        cancelText={'No'}
        agreeText={'Yes'}
        onClose={handleCancel}
        onAgree={handleAgreeDel}
        desciption="Do you really want to delete this contract? This process cannot be undone."
      /> */}
    </MuiCard>
  );
};
export default ProjectCardItem;
