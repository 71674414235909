import { Box, styled } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';


const Container = styled(Box)(({ theme }) => ({
  padding: '20px 30px',
}));

const TitleWrapper = styled('div')(({ theme }) => ({
  fontSize: 20,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // borderBottom: 'solid 1px',
  borderColor: theme.shape.borderColor,
}));

const Title = styled('h3')(({ theme }) => ({
  fontSize: 20,
  fontWeight: theme.typography.fontWeightBold,
  margin: 0,
}));

const CloseButton = styled(CloseIcon)({
  cursor: 'pointer',
});

const ModalHeaderBasic = ({ title, subTitle = '', onClose }) => {
  return (
    <Container>
      <TitleWrapper>
        <Title>{title}</Title>
        <CloseButton onClick={onClose} />
      </TitleWrapper>
      {subTitle && <Title sx={{ fontSize: 14, marginTop: '5px' }}>{subTitle}</Title>}
    </Container>
  );
};

export default ModalHeaderBasic;
