import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import CreateSMC from 'assets/images/create_smart_contract.svg';

import { PrimaryButton } from 'common/form/ButtonStyle';
import useGenerateContract from 'hooks/useGenerateContract';
import CustomContractModal from 'common/Modals/CustomContract';
import Loader from 'common/Loader';

const CreateSmartContract = ({ setCreate }) => {

    const {
        prompt,
        isModalOpen,
        functionList,
        loading,
        handleCloseModal,
        handleKeyup,
        handleEditDescription,
        handleChangePrompt,
        handleChangeCheckbox,
        handleChangeDescription,
        handleGenerateDescription,
        setOpenModel,
        handleGenerateContract
    } = useGenerateContract();

    const handleClick = () => {
        console.log("open");
        setOpenModel(true);
    };
    return (
        <Container
            maxWidth="xl"
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ marginBottom: '3rem' }}>
                {/* <img src="assets/images/create_smart_contract.svg" alt="" /> */}
                <img src={CreateSMC} />

            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h1" sx={{ fontSize: '1.5rem' }}>
                    Get started by creating your first smart contract
                </Typography>
                <Typography variant="body1" sx={{ opacity: '0.6', marginBottom: '2rem' }}>
                    Create and manage all of your smart contracts here
                </Typography>
                <PrimaryButton
                    id="create-contract-button"
                    onClick={handleClick}
                    variant="contained"
                    sx={{
                        width: '178px',
                        height: '54px',
                        borderRadius: '7px',
                    }}>
                    {' '}
                    Create Contract
                </PrimaryButton>
            </Box>
            {loading && <Loader showText={loading} />}
            <CustomContractModal
                prompt={prompt}
                isModalOpen={isModalOpen}
                functionList={functionList}
                handleKeyup={handleKeyup}
                handleChangeCheckbox={handleChangeCheckbox}
                handleEditDescription={handleEditDescription}
                handleCloseModal={handleCloseModal}
                handleChangePrompt={handleChangePrompt}
                handleChangeDescription={handleChangeDescription}
                handleGenerateDescription={handleGenerateDescription}
                handleGenerateContract={handleGenerateContract}
            />
        </Container>
    );
};

export default CreateSmartContract;
