import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography, Paper } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { supportedChains } from 'config/networkChain'

const DetailsView = () => {
  const { contractState } = useSelector((state) => state?.contract)
  const blockExplorerUrl = supportedChains?.find(chain => chain.chain_id === contractState.chain).blockExplorerUrl || '';
  const deployedContractHashUrl = `${blockExplorerUrl}extrinsic/${contractState.hash}`;
  const theme = useTheme();

  return <Box display={'flex'} flexDirection={'column'}>
    <Box id="contractAddress">
      <Typography variant="h2" gutterBottom>
        Contract Address
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '750px',
          width: '65%',
          background: theme.palette.background.light,
          padding: '0.25rem 0.5rem',
          marginBottom: '1rem',
        }}>
        <Typography>{contractState?.address}</Typography>
        <Box>
          <CopyToClipboard text={contractState?.address}>
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </Box>
      </Paper>
    </Box>

    <Box id="transactionHash">
      <Typography variant="h2" gutterBottom>
        Transaction Hash
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '750px',
          width: '65%',
          background: theme.palette.background.light,
          padding: '0.25rem 0.5rem',
          marginBottom: '1rem',
        }}>
        <Typography>{contractState?.hash}</Typography>
        <Box>
          <CopyToClipboard text={contractState?.hash}>
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </Box>
      </Paper>
    </Box>

    <Box id="blockExplorer">
      <Typography variant="h2" gutterBottom>
        Block Explorer
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '750px',
          width: '65%',
          background: theme.palette.background.light,
          padding: '0.25rem 0.5rem',
          marginBottom: '1rem',
        }}>
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', width: '80%'}}>{deployedContractHashUrl}</Typography>
        <Box>
          <CopyToClipboard text={deployedContractHashUrl}>
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>

          <Link to={deployedContractHashUrl} target="_blank" >
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </Link>
        </Box>
      </Paper>
    </Box>
  </Box>
}

export default DetailsView;