import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        common: {
            black: '#000',
            white: '#f2f2f2',
        },
        background: {
            default: '#0a0933',
            dark: '#000000',
            white: '#ffffff',
            light_op: 'linear-gradient(217deg, rgba(196, 0, 97, .8), rgba(196, 0, 97, 0) 70%), linear-gradient(127deg, rgba(230, 0, 122, .8), rgba(230, 0, 122, 0) 70%), linear-gradient(336deg, rgb(49 30 109 / 70%), rgba(103, 69, 210, 0) 70%)',
            dark_op: 'linear-gradient(360deg, rgb(255 255 255 / 5%) -9.33%, rgb(19 26 50) 119.91%)',
            light: 'linear-gradient(9deg, #02031e 0%, #282a53 100.23%)',
            modal_head: 'linear-gradient(360deg, #02031e 50%, #282a53 100.23%)',
            solid_dark: '#121337',
            parentNode: '#aeaaae33',
            disabled: '#AEAEAE',
        },
        primary: {
            main: '#4D71D3',
            light: '#f2f2f2',
            dark: '#000000',
            white: '#f2f2f2',
            contrastText: '#2E2E30',
            light2: '#FA6E6E',
            pink: '#e6007a',
            red1: '#CC2222',
            grey1: '#8C8C8C',
            purple: '#EF6BFE',
            purple2: '#DD90E5',
            yellow: '#FFD33F',
            green1: '#64F5A6',
        },
        success: {
            main: '#95D5B2',
            light: '#ace4c5',
            dark: '#094D27',
            contrastText: '#2E2E30',
        },
        info: {
            main: '#64A8F9',
            dark: '#3498DB',
            contrastText: '#2E2E30',
        },
        warning: {
            main: '#FFD33F',
            light: '#E3C86D',
            dark: '#392D04',
            contrastText: '#2E2E30',
        },
        error: {
            main: '#D14343',
            light: '#FDE8E8',
            contrastText: '#f2f2f2',
        },
        text: {
            primary: '#E1E1E1',
            secondary: '#F6F6F6',
            colorNode: '#4D4D4D',
            disabled: '#747474',
        },
    },
    shape: {
        borderRadius: 4,
        borderColor: '#8C8C8C',
        backgroundNode: '#1e1e1e',
    },
    hover: {
        background: {
            dark: '#4b4b4c',
        },
    },
    typography: {
        ubuntuRegular: 'normal normal normal 14px/16px Ubuntu-Regular',
        ubuntuLight: 'normal normal 300 16px/18px Ubuntu-Light',
        ubuntuMedium: 'normal normal 500 16px/18px Ubuntu-Medium',
        ubuntuBold: 'normal normal bold 16px/18px Ubuntu-Bold',
        fontFamily: 'Ubuntu',
        color: '#f2f2f2',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        caption: {
            fontFamily: 'Ubuntu',
            fontSize: '0.75rem',
            fontWeight: 300,
            lineHeight: 1.375,
        },
        h1: {
            fontFamily: 'Ubuntu',
            fontWeight: 600,
            fontSize: '1.25rem', //20px
            lineHeight: 1.375,
        },
        h2: {
            fontFamily: 'Ubuntu',
            fontWeight: 600,
            fontSize: '1.125rem', //18px
            lineHeight: 1.375,
        },
        h3: {
            fontFamily: 'Ubuntu',
            fontWeight: 600,
            fontSize: '1rem', //16px
            lineHeight: 1.375,
        },
    },
    components: {
        truncate: {
            singleLineEllipsis: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            twoLineEllipsis: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2!important',
                WebkitBoxOrient: 'vertical',
            },
            threeLineEllipsis: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3!important',
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'normal',
            },
        },
    },
});
