import React from 'react';
import FormLayout from 'common/layout/FormLayout';
import Form from 'common/form/form';
import Loader from 'common/Loader';
import { object, string } from 'yup';
import useUserForm from 'hooks/useUserForm';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material';
import { login } from 'store/services/auth';
import { toast } from 'react-toastify';
// import { useAuth } from 'hooks/useAuth';
// import { useDispatch } from 'react-redux';


const PageContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const SigninSchema = object({
  email: string().required('Email is required').email('Email must be valid'),
  password: string().required('Password is required'),
});
const defaultErrors = {
  email: [],
  password: [],
};
const defaultFields = {
  email: '',
  password: '',
};

const SignIn = () => {
  // const { player } = useDispatch();
  const navigate = useNavigate();

  // useAuth();
  const { errors, userCredentials, loading, handleChange, handleSubmit } = useUserForm({
    schema: SigninSchema,
    defaultErrors,
    defaultFields,
  });
  const fields = [
    {
      type: 'email',
      label: 'Email Address',
      name: 'email',
      id: 'email',
      value: userCredentials.email,
      errors: errors.email[0],
    },
    {
      type: 'password',
      label: 'Password',
      name: 'password',
      id: 'password',
      value: userCredentials.password,
      errors: errors.password[0],
    },
  ];

  const onSubmition = async () => {
    try {
      await login(userCredentials)
      navigate("/smartcontracts");
    }
    catch (err) {
      toast.error(err, {
        style: { top: '3.5em' },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <PageContainer>
        <Form fields={fields} handleChange={handleChange} handleSubmit={(event) => handleSubmit(event, onSubmition)} callingFrom='signin' />
      </PageContainer>
    </>
  );
};

SignIn.PageLayout = (props) =>
  FormLayout({
    children: props.children,
    page: 'signin',
  });

export default SignIn;
