import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import loader from 'assets/images/loader.gif';

const LoadingScreen = styled('div')(({ theme, sx }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1450,
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: 'rgb(28,28,28,0.8)',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: 18,
  border: 'none',
  gap: 15,
  ...sx
}));

const TextScreen = styled('div')(() => ({
  textAlign: 'center',
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
  background: '#181818',
  padding: '10px 30px',
  borderRadius: '5px',
}));

const Loader = ({ isScreenLoading = true, showText = false, children = null, sx = {} }) => {
  const [textFlag, setTextFlag] = useState(true);
  useEffect(() => {
    let textInterval;
    if (showText) {
      textInterval = setInterval(() => {
        setTextFlag((prevState) => !prevState);
      }, 3000);
    }
    return () => {
      clearInterval(textInterval);
    }
  }, []);

  const renderLoader = <img src={loader} width="150" height="150" alt="loader" />;
  const renderText = (
    <TextScreen>
      {children}
    </TextScreen>
  );
  return (
    <>
      {isScreenLoading ? (
        <LoadingScreen sx={sx}>{showText && textFlag ? renderText : renderLoader}</LoadingScreen>
      ) : (
        <>
          {/* This is small loader used for api calls mainly */}
          {renderLoader}
        </>
      )}
    </>
  );
};

export default Loader;
