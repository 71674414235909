import React from 'react';
import FormLayout from 'common/layout/FormLayout';
import Form from 'common/form/form';
import Loader from 'common/Loader';
import { object, string, ref } from 'yup';
import useUserForm from 'hooks/useUserForm';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { signup } from 'store/services/auth';
import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { useAuth } from 'hooks/useAuth';

const SignupSchema = object({
  firstName: string().required('First name is required').min(3, 'First name must be at least 3 characters long'),
  lastName: string().required('Last name is required').min(3, 'Last name must be at least 3 characters long'),
  email: string().required('Email is required').email('Email must be valid'),
  password: string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
  confirmPassword: string()
    .required('Confirm password is required')
    .oneOf([ref('password'), null], 'Passwords does not match'),
});
const defaultErrors = {
  firstName: [],
  lastName: [],
  email: [],
  password: [],
  confirmPassword: [],
};
const defaultFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const PageContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const SignUp = () => {
  // useAuth();
  // const { player } = useDispatch();
  const navigate = useNavigate();
  const { errors, userCredentials, loading, handleChange, handleSubmit } = useUserForm({
    schema: SignupSchema,
    defaultErrors,
    defaultFields,
  });
  const fields = [
    {
      type: 'text',
      label: 'First Name',
      name: 'firstName',
      id: 'firstname',
      value: userCredentials.firstName,
      errors: errors.firstName[0],
    },
    {
      type: 'text',
      label: 'Last Name',
      name: 'lastName',
      id: 'lastname',
      value: userCredentials.lastName,
      errors: errors.lastName[0],
    },
    {
      type: 'email',
      label: 'Email Address',
      name: 'email',
      id: 'email',
      value: userCredentials.email,
      errors: errors.email[0],
    },
    {
      type: 'password',
      label: 'Password',
      name: 'password',
      id: 'password',
      value: userCredentials.password,
      errors: errors.password[0],
    },
    {
      type: 'password',
      label: 'Confirm Password',
      name: 'confirmPassword',
      id: 'confirmpassword',
      value: userCredentials.confirmPassword,
      errors: errors.confirmPassword[0],
    },
  ];

  const onSubmition = async () => {
    try {
      const payload = { ...userCredentials };
      delete payload.confirmPassword;

      await signup(payload)
      navigate("/smartcontracts");
    }
    catch (err) {
      toast.error(err, {
        style: { top: '3.5em' },
      });
    }
  };

  return (
    <PageContainer>
      {loading && <Loader />}
      <Form
        fields={fields}
        handleChange={handleChange}
        handleSubmit={(event) => handleSubmit(event, onSubmition)}
        callingFrom='signup'
      />
    </PageContainer>
  );
};

SignUp.PageLayout = (props) =>
  FormLayout({
    children: props.children,
    page: 'signup',
  });

export default SignUp;
