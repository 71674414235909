import { getRequest, postRequest } from 'utils/httpRequest';

export const getContractsList = async (params) => {
    return await getRequest({ url: `contracts`, params });
} 

export const getContract = async (contractId) => {
    return await getRequest({ url: `contracts/${contractId}` });
} 

export const updateContract = async (contractId, payload) => {
    return await postRequest({ url: `contracts/${contractId}/update`, body: payload });
} 

export const searchContract = async (payload) => {
    return await postRequest({ url: `contracts/search`, body: payload });
} 

export const generateDescription = async (payload) => {
    return await postRequest({ url: 'contracts/generate/description', body: payload });
} 

export const generateContract = async (payload) => {
    return await postRequest({ url: 'contracts/generate/new', body: payload });
} 

export const compileContract = async (payload) => {
    return await postRequest({ url: 'contracts/compile', body: payload });
} 
