import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from '@mui/system';
import { styled } from '@mui/material';
import { useEffect } from "react";
import { getUserState } from "utils/httpRequest";

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  // background: '#0c0d2c',
}));

function BaseLayout() {
  const router = useNavigate();
  const location = useLocation();
  const { token } = getUserState();

  useEffect(() => {

    if (location.pathname === '/' && !token)
      router('/signin', { replace: true });

    else if ((location.pathname === '/' ||
      location.pathname === '/signin' ||
      location.pathname === '/signup') && token) {

      router('/smartcontracts', { replace: true });
    }
  }, []);

  return (
    <PageContainer>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Outlet />
      </Box>
    </PageContainer>
  );
}

export default BaseLayout;
