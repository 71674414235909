import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import { Box } from '@mui/material';

const Select = ({label, value, onChange, children }) => {
    return (
        <Box>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
                <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
                <MuiSelect
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value}
                    label={label}
                    onChange={onChange}
                >
                    {children}

                </MuiSelect>
            </FormControl>
        </Box>
    )
}

export default Select;