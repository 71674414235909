import { useState } from 'react';

const useUserForm = ({schema, defaultErrors, defaultFields}) => {
  const [errors, setErrors] = useState(defaultErrors);
  const [userCredentials, setUserCredential] = useState(defaultFields);
  const [isFormInvalid, setFormInvalid] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateFields = (_userCredentials) => {
    schema.validate(_userCredentials, { abortEarly: false }).then(() => {
      setErrors(defaultErrors);
    }).catch((err) => {
      let errorDetails = {...defaultErrors}
      Object.values(err.inner).forEach((elem) => {
        errorDetails = {...errorDetails, [elem.path]: [...errorDetails[elem.path], elem.message]}
      })
      setErrors(prevErrors => ({...prevErrors, ...errorDetails}))
    })
  }
  const handleChange = (e) => {
    setUserCredential(prev => {
      let _userCredentials = {...prev, [e.target.name]: e.target.value}
      if(isFormInvalid){
        validateFields(_userCredentials);
      }
      return _userCredentials
    });
  }
  const handleSubmit = async (event, onSubmition) => {
    try {
      event.preventDefault();
      const isFormValid = await schema.isValid(userCredentials, {
        abortEarly: false, // Prevent aborting validation after first error
      });
      if (!isFormValid) {
        setFormInvalid(true);
        validateFields(userCredentials);
        return;
      }
      setErrors(defaultErrors);
      setLoading(true)
      await onSubmition();
    } catch (error) {
      console.log("=================>Error", error)
    }finally{
      setLoading(false);
    }
  };
  return {
    errors,
    userCredentials,
    loading,
    handleChange,
    handleSubmit,
    setLoading
}
}
export default useUserForm;
