import React from 'react';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditCodeIcon from 'assets/icons/icon-edit-code.svg';
import TextField from '@mui/material/TextField';

const ListContainer = styled(Box)(({ theme }) => ({
  paddingRight: '10px',
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& > svg': {
    fill: '#E1E1E1',
    width: '25px',
  },
}));
const StyledDescription = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& > button': {
    display: 'none',
  },
  '&:hover': {
    '& > button': {
      display: 'inline-block',
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.light,
  width: '100%',
  padding: 5,
  border: `1px solid ${theme.palette.background.dark}`,
  '& > div > textarea': {
    fontSize: 14,
    // fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7a7a7a',
      borderRadius: '4px',
    },
  },
}));

function ViewList({ functionList, handleChangeCheckbox, handleEditDescription, handleChangeDescription }) {
  const theme = useTheme();
  const lastIndex = functionList?.length - 1;

  return (
    <ListContainer>
      {functionList?.length
        ? functionList?.map((func, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  background: theme.palette.background.light,
                  marginBottom: lastIndex === index ? `0` : `10px`,
                }}>
                <Box sx={{width: '20%', display: 'flex', alignItems: 'center', cursor: 'pointer'}} component={'label'}>
                {'isChecked' in func && (
                  <Checkbox
                    disableRipple
                    checked={func?.isChecked}
                    onChange={(event) => handleChangeCheckbox(event, index)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                {func?.name && (
                  <Tooltip title={func.name} arrow>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      fontSize: 14,
                        fontWeight: theme.typography.fontWeightBold,
                        fontFamily: theme.typography.fontFamily,  
                      }}>
                      {func.name}
                    </Typography>
                  </Tooltip>
                )}
                </Box>
                <StyledDescription sx={{ width: `${func.name ? '80%' : '100%'}` }}>
                  {func.editMode ? (
                    <StyledTextField
                      id="filled-multiline-flexible"
                      name="textField"
                      multiline
                      value={func.description}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      maxRows={3}
                      variant="standard"
                      onChange={(event) => handleChangeDescription(event, index)}
                    />
                  ) : (
                    <Typography
                      sx={{
                        width: `${func.name ? '93%' : '100%'}`,
                        padding: `${func.name ? '5px 0' : '5px 12px'}`,
                        textAlign: 'justify',
                        fontSize: 14,
                        // fontWeight: theme.typography.fontWeightBold,
                        fontFamily: theme.typography.fontFamily,
                      }}>
                      {func.description}
                    </Typography>
                  )}
                  {func.name && !func.editMode && (
                    <StyledIconButton
                      sx={{ position: 'absolute', top: '-10px', right: 0, zIndex: 1 }}
                      onClick={() => handleEditDescription(index)}>
                      <img src={EditCodeIcon} width="20px" height="20px" alt="edit-icon"/>
                    </StyledIconButton>
                  )}
                </StyledDescription>
              </Box>
            );
          })
        : null}
    </ListContainer>
  );
}

export default ViewList;
