import React from 'react';
const { styled } = require('@mui/material');

const PinkLabel = styled('div')(({ theme }) => ({
  font: theme.typography.ubuntuMedium,
  color: theme.palette.primary.white,
  marginBottom: 3,
}));

const BasicLabel = styled('div')(({ theme }) => ({
  font: theme.typography.ubuntuMedium,
  color: theme.palette.text.white,
  marginBottom: 3,
}));

const ThinLabel = styled('div')(({ theme }) => ({
  font: theme.typography.ubuntuMedium,
  fontWeight: theme.typography.fontWeightLight,
  color: '#699e00',
  marginBottom: 13,
}));
const FormLabel = styled('div')(({ theme }) => ({
  font: theme.typography.ubuntuMedium,
  color: '#E1E1E1',
  margin: '5px 0',

}));

const Label = ({ children, type = 'pink' }) => {
  if (type === 'pink') return <PinkLabel>{children}</PinkLabel>;
  if (type === 'basic') return <BasicLabel>{children}</BasicLabel>;
  if (type === 'thin') return <ThinLabel>{children}</ThinLabel>;
  if (type === 'form') return <FormLabel>{children}</FormLabel>;
  return <div></div>;
};

export default Label;
