import React from 'react';
import { styled, Box } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedContract } from 'store/reducers/contract';

const HeaderContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#303030',
}));

const BodyContainer = styled('div')(({ theme }) => ({
  fontSize: '14px',
  '.iconClass': {
    display: 'none',
  },
}));

const Header = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: 600,
  padding: '3px 6px',
}));

const FilesView = () => {

  const dispatch = useDispatch();
  const { contractState } = useSelector(state => state?.contract);

  const filesTree = [
    {
      id: 'files',
      label: 'Contract',
      children: [
        { id: 'lib', label: 'lib.rs' },
        { id: 'toml', label: 'cargo.toml' }
      ],
    },
  ];

  const handleItemSelection = (event, itemId, isSelected) => {
    if (itemId === 'files') {
      event.stopPropagation();
      return null;
    }

    if (isSelected) {
      const payload = itemId === 'toml' ? contractState?.externalFiles : [contractState];
      dispatch(setSelectedContract(payload[0]))
    }
  };

  return (
    <Box>
      <HeaderContainer>
        <Header> File Explorer </Header>
      </HeaderContainer>
      <BodyContainer>
        <RichTreeView multiSelect items={filesTree} onItemSelectionToggle={handleItemSelection} />
      </BodyContainer>
    </Box>
  );
};

export default FilesView;
