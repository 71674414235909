import router from "config/routes";
import { theme } from "config/theme";
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from 'store'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import SubWalletModule from '@subwallet-connect/subwallet-polkadot';
import { init, Web3OnboardProvider } from '@subwallet-connect/react'

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";


// initialize the module
const subWallet= SubWalletModule()
const wallets = [
subWallet
]
// initialize Onboard
const web3Onboard = init({
  wallets,
  chains: [], //EVM chains
chainsPolkadot:[
    {
      id: '0x91b171bb158e2d3848fa23a9f1c25182fb8e20313b2c1eb49219da7a70ce90c3',
      namespace: 'substrate',
      token: 'DOT',
      label: 'Polkadot',
      blockExplorerUrl: `polkadot.api.subscan.io`,
      decimal: 10
    }
],
accountCenter: {
  desktop: {
    position: 'topRight',
    enabled: false,
    minimal: true
  },
},
connect: {
  autoConnectLastWallet: true
}
})

function App() {

  let themeConfig = createTheme(theme)

  themeConfig = responsiveFontSizes(themeConfig);

  return (
    <Provider store={store}>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
      <ThemeProvider theme={themeConfig}>
        <ToastContainer style={{ top: 74 }} />
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Web3OnboardProvider>
    </Provider>
  );
}

export default App;
