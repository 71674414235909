import React from 'react';
import { styled } from '@mui/material';
// import useUserActive from '@/hooks/useUserActive';

const PageContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  background: theme.palette.background.dark,
}));
const ChildrenContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function SignupPageLayout(props) {
//   useUserActive();
  return (
    <>
      <PageContainer>
        <div style={{ height: '100%', width: '100%' }}>
          <ChildrenContainer>{props.children}</ChildrenContainer>
        </div>
      </PageContainer>
    </>
  );
}

export default SignupPageLayout;
