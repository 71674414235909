import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
// import TemplateItem from '../TemplateItem/TemplateItem';
// import Pagination from '@mui/material/Pagination';
// import { CustomScrollbar } from '@/components/atom/CustomScrollBar';
import CreateSmartContract from './CreateSmartContract';
import Loader from 'common/Loader';
import { CustomScrollbar } from 'common/CustomScrollbar';
import ProjectCardItem from './ProjectCardItem';

const ListSmartContract = ({ setCreate, userContracts, loadingState }) => {
    
    // const theme = useTheme();

    if (loadingState)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '70vh',
                }}>
                <Loader isScreenLoading={false} />
            </div>
        );
    return (
        <>
            {userContracts.length === 0 && !loadingState && <CreateSmartContract setCreate={setCreate} />}
            <Grid container columnSpacing={2} mt={'13px'}>

                <Grid item xs={2}>
                    <Box sx={{ background: '#1E1E1F', height: '100%' }}></Box>
                </Grid>
                <Grid item xs={12}>
                    <CustomScrollbar
                        style={{
                            height: 'calc(100vh - 275px)',
                            overflowX: 'hidden',
                        }}
                        autoHide>
                        <Box
                            sx={{
                                fontSize: '14px',
                                alignItems: 'center',
                                // height: '100%'
                                minHeight: '70vh',
                            }}>
                            <Grid
                                id="smart-contracts-items"
                                container
                                columnSpacing={{ md: 2, lg: 2, xl: 2, sm: 1, xs: 1 }}
                                rowSpacing={{ md: 2, lg: 2, xl: 2, sm: 1, xs: 1 }}>
                                {userContracts
                                    ?.map((item, key) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                xl={4}
                                                key={key}
                                                sx={{
                                                    '@media screen and (min-width:0px) and (max-width:600px)': {
                                                        marginRight: '40px',
                                                    },
                                                }}>
                                                <ProjectCardItem key={key} contract={item} />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Box>
                    </CustomScrollbar>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            margin: '20px auto',
                            '@media screen and (min-width:0px) and (max-width:600px)': {
                                marginRight: '40px',
                            },
                        }}>
                        {/* <Pagination
                            sx={{
                                '& .MuiPagination-ul': {
                                    '& .Mui-selected': {
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.white,
                                    },
                                    '& .Mui-selected:hover': {
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                    '& .MuiPaginationItem-icon': {
                                        color: theme.palette.primary.main,
                                    },
                                },
                            }}
                            variant="outlined"
                            shape="rounded"
                            count={meta?.totalPage}
                            defaultPage={1}
                            page={page}
                            onChange={(_, value) => {
                                setPage(value);
                                //scroll to titleDialog
                            }}
                        /> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default ListSmartContract;
