import { createBrowserRouter } from "react-router-dom";
import SmartContractLayout from "common/layout/SmartContractLayout";
import BaseLayout from "common/layout/BaseLayout";
import SignIn from "pages/signIn";
import SignUp from "pages/signUp";
import Dashboard from "pages/dashboard";
import SmartContract from "pages/smartContract"
import Deployment from "pages/deployment";

const router = createBrowserRouter([
    {
        path: "/",
        element: <BaseLayout />,
        children: [
            { path: "/signin", element: <SignIn /> },
            { path: "/signup", element: <SignUp /> },
            { path: "/smartcontracts", element: <SmartContractLayout /> , children: [
                { path: "/smartcontracts/", element: <Dashboard /> },
                { path: "smartcontracts/:id/deployment", element: <Deployment /> },
                { path: "/smartcontracts/:id", element: <SmartContract /> },

            ]},
            { path: "/deployment", element: <SmartContractLayout /> , children: [
                { path: "/deployment/:id", element: <Deployment /> },
            ]},
        ],
    },
]);

export default router;
